
import Navbar from "./components/Navbar";
import Home from './components/Home';
import SolutionHeading from "./components/SolutionHeading";
import SolutionOne from "./components/SolutionOne";
import SolutionThree from "./components/SolutionThree";
import SolutionTwo from "./components/SolutionTwo";
import Footer from './components/Footer';
import Carousel from './components/Carousel';
import FAQ from "./components/FAQ";
import ScrollIndicator from "./components/ScrollIndicator";

function App() {
  return (
    <>
      <Navbar />
      <ScrollIndicator />
      <Home />
      <SolutionHeading />

      {/* <SolutionThree />*/}
      <SolutionTwo />
      |{/* <Carousel />*/}
      <FAQ />
      <Footer />
    </>
  );
}

export default App;